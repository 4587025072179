<template>
  <div id="ai-services_vca_video-celebrity-detection">
    <service-template
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :run-disabled="currentExample === null"
      :icon="require('@/assets/images/icons/services/header/VCA-CBT.svg')"
      type="video"
      service="vca"
    >
      <template v-slot:header>
        {{ $t('services.videoCelebrityDetection.header') }}
      </template>
      <template v-slot:left>
        <carousel
          :step="1"
          :title="$t('services.carousel.videoTitle')"
          type="video"
        >
          <span
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            class="example"
            @click="() => onExampleClick(index)"
          >
            <video
              :src="`${example}#t=0.01`"
              preload="metadata"
              muted
              class="example"
            />
          </span>
        </carousel>
        <upload
          file-type="video"
          :file-size="2"
          :example-file="examples[currentExample]"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.videoCelebrityDetection.header')"
          :loading="loading"
          :subtitle-extra="$t(`button['Check the full list of celebrities']`)"
          :subtitle-extra-click="showFullListOfCelebrities"
        >
          <result-chart
            v-if="result"
            :chart-data="chartData"
          />
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';
import ResultChart from '@/views/components/ResultChart/Index.vue';

export default {
  name: 'VideoLandmarkDetection',
  components: {
    ResultChart,
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
  }),
  computed: {
    examples() {
      return [
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/ceb/post_malone.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/ceb/pink.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/ceb/ln_manuel_miranda.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/ceb/drake.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/ceb/kanye_and_kim.mp4`,
      ];
    },
    chartData() {
      return Object.keys(this.result.detected_celebrity_with_probability).map((celebrity) => ({
        name: celebrity,
        percentage: Math.round(this.result.detected_celebrity_with_probability[celebrity] * 10000) / 100,
      }));
    },
  },
  watch: {
    currentExample() {
      this.result = null;
    },
  },
  methods: {
    run() {
      this.loading = true;
      return this.$api.vca.videoCelebrityDetection.detectCelebrity(this.examples[this.currentExample]);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      this.currentExample = index;
    },
    onUploadRemove() {
      this.result = null;
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
    showFullListOfCelebrities() {
      const routeData = this.$router.resolve({ path: '/labels/video-celebrity-detection' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  #ai-services_vca_video-celebrity-detection {
    background: $background-secondary;

    .example {
      height: 100%;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
</style>
